body {
    background-color: #f4f4f4;
    font-size: 14px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: "Spectral", Helvetica, Arial, serif;
}

@media (max-width: 576px) {
    body {
        font-size: 12px;
    }
}

.clickable {
    cursor: pointer;
}
